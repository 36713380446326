export default defineNuxtRouteMiddleware((to) => {
  const authStore = useAuthStore();

  if (!authStore.isAuthenticated && !authStore.isGuest) {
    return navigateTo({
      name: 'login',
      query: { redirect: to.path }
    });
  }
});
